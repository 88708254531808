// External Dependencies
import styled from 'styled-components';

// Local Typings
interface Props {
  children: React.ReactNode;
}

// Local Variables
const StyledDiv = styled.div({
  margin: '0 auto',
  width: '95vw',
});

// Component Definition
const Container = ({ children }: Props): JSX.Element => {
  return (
    <StyledDiv>
      {children}
    </StyledDiv>
  );
};

export default Container;
